<template>
  <div class="main-box">
    <div class="box">
      <div class="bg_login">
      </div>
      <div>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
// import * as THREE from 'three'
// import CLOUDS from "vanta/src/vanta.clouds"


/**
 * birds、clouds/clouds2、waves、fog、globe/net/rings
 */
export default {
  // beforeDestroy() {
  //   if (this.vantaEffect) {
  //     this.vantaEffect.destroy()
  //   }
  // },
  // mounted() {
  //   this.vantaEffect = CLOUDS({
  //     el: this.$refs.vantaRef,
  //     THREE: THREE
  //   })
  //   this.vantaEffect.setOptions({
  //     mouseControls: true,
  //     touchControls: true,
  //     gyroControls: false,
  //     minHeight: 200.00,
  //     minWidth: 200.00,
  //     speed: 1.50
  //   })
  // },
}
</script>

<style scoped lang="scss">
:root {
  width: 100vw;
  height: 100vh;
}

.main-box {
  background: #eff6fc;
  width: 100vw;
  height: 100vh;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    display: flex;
    min-width: 1024px;
    height: 600px;
    border-radius: 10px;

    .bg_login {

      width: 800px;
      z-index: 1;
      border-radius: 10px 0 0 10px;
      background-image: url("../assets/images/bg-login.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-blend-mode: difference;
    }
  }
}

</style>
